import { useEffect, useState, useCallback } from "react";
function useMobile(customWidth: number = 1024, desactiveMobile: boolean = false): boolean {
  if (desactiveMobile) return;
  const [isMobile, setIsMobile] = useState<Readonly<boolean>>(false);
  const isScreenMobile = useCallback((): boolean => {
    if (typeof window === "undefined") return false;
    let width: number = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return width < customWidth;
  }, []);
  useEffect(() => {
    const handleResize = (): void => {
      const mobile: boolean = isScreenMobile();
      if (mobile !== isMobile) {
        setIsMobile(mobile);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, isScreenMobile]);
  return isMobile;
}
export default useMobile;